@keyframes opacity2 {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1;
  }
}


.defaultImg{
  height: 578px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
      // width: 1920px;
      // img{
      //   width: 100%;
      // }
}
.tutor {
  max-width: 1200px;
  margin: 116px auto 40px;
  // padding: 20px;
  // background: #fff;
  .contentArea {
    // display: flex;
    // justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(4, 22%);
    grid-column-gap: 48px;
    margin-bottom: 40px;
  
    .totur-card {

      &:nth-child(even) {
        margin-top: 87px;
      }
    }
  }

  img {
    width: 100%;
  }
}

.tutorCard {
  width: 264px;
  cursor: pointer;
  // min-height: 354px;

  .avator {
    width: 100%;
    height: 354px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
    }
  }

  .content-body {
    position: relative;

    .name {
      text-align: center;
      font-size: 20px;
      margin: 20px 0;
      font-weight: 400;
    }

    .intro {
      width: 100%;
      font-size: 14px;
    }

    .modal {
      top: -80px;
      left: 17px;
      position: absolute;
      width: 226px;
      background: #386FEC;
      box-shadow: 0px 3px 12px 0px rgba(16, 16, 16, 0.22);
      border-radius: 4px;
      animation: opacity2 0.7s;
      padding: 20px;
      color: #ffffff;

      .modal-name {
        font-size: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #ffffff;
        text-align: center;
      }

      .modal-intro {}

      .modal-button {
        width: 108px;
        height: 31px;
        background: #F4F4F4;
        border-radius: 16px;
        color: #386FEC;
        font-size: 12px;
        line-height: 31px;
        text-align: center;
        display: block;
      }
    }
  }

}

.tutor-detail {
  max-width: 1200px;
  margin: 40px auto;

  .tutor-content {
    display: flex;
    margin-top: 40px;

    .content-card {
      margin-right: 34px;
    }

    .content-detail {
      flex: 1;

      .detail-video {
        width: 600px;
        height: 350px;
        margin: 0 auto 20px;

        video {
          height: 100%;
          width: 100%;
        }
      }

      .detail-text {
        white-space: pre-wrap;
      }
    }
  }
}
@primary-color: #063FC6;