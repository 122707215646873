.showImg{
  height: 497px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
      // width: 1920px;
      // img{
      //   width: 100%;
      // }
}
@primary-color: #063FC6;