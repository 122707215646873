.layout {
    min-width: 1200px;

    li {
        list-style: none;
    }

    .header {
        height: 64px;
        background: #ffffff;
        box-shadow: 0px 3px 12px 0px rgba(16, 16, 16, 0.08);

        .menu {
            width: 1200px;
            height: 100%;
            margin: 0 auto;

            .curItem {
                &:hover {
                    color: #ffffff;
                }
            }

            .logo {
                float: left;
                margin-right: 140px;
                cursor: pointer;
            }

            .ant-menu-title-content {
                font-size: 16px;
            }
        }

        .persen {
            float: right;
            width: 80px;
            display: flex;
            justify-content: space-between;

            .navList {
                color: #000000;

                &:hover {
                    color: #063FC6;
                }
            }

            .textElli {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .dropdown-style {

                // .ant-menu-item-hover {
                //     background-color: #6E0A3D !important;
                //     color: #ffffff !important;
                // }
                .ant-menu-item-active {
                    background-color: #6E0A3D !important;
                    color: #ffffff !important;
                }
            }
        }
    }

    .content {
        min-height: calc(~"100vh - 264px");
    }

    .footer {
        height: 200px;
        background: #282828;
        min-width: 1200px;

        .footer_content {
            max-width: 1200px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #666666;
            height: 100%;
            margin: 0 auto;

            .content_center {
                display: flex;
                align-items: center;
                justify-content: space-between;

                ul {
                    padding: 0 20px;
                }

                li {
                    margin-bottom: 5px;
                }
            }

            .erweima {
                img {
                    display: block;
                    margin: 0 auto 10px;
                }
            }
        }
    }
}
@primary-color: #063FC6;