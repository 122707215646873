.home {

  // width: 1920px;
  li {
    list-style: none;
  }

  .normal_width {
    width: 1200px;
    margin: 0 auto;
  }

  .baseInfo {
    height: 497px;
    // display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    min-width: 1200px;
    margin: 0 auto;
    position: relative;
    width: 100%;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;

    .bg_img {
      width: 100%;
      height: 100%;
    }

    .info_list {
      position: absolute;
      width: 1200px;
      // top:40%;
      // left: 50%;
      top: 6%;
      text-align: center;
      font-weight: bold;
      color: #FFFFFF;
      font-size: 20px;

      h1 {
        font-size: 60px;
        font-weight: bold;
        color: #FFFFFF;
        letter-spacing: 5px;
        text-shadow:
          1px -1px 0 #063FC6,
          -1px 2px 1px #063FC6,
          -2px 4px 1px #063FC6,
          -3px 6px 1px #063FC6;
      }

      .info_list_info {
        display: flex;
        justify-content: space-around;
        margin: 20px 0 40px;
      }

      .enterButton {
        position: relative;

        .enterButton_text {
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -40%);
          color: #1890ff;
          font-size: 34px;
          cursor: pointer;
        }
      }
    }
  }

  .video {
    padding: 40px 0;
    background-color: #ffffff;

    .video_content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .video-left {
        height: 325px;
        width: 578px;

        video {
          width: 100%;
          height: 100%;
        }
      }

      .video-right {
        width: 538px;

        h2 {
          font-weight: bold;
          margin-bottom: 20px;
        }

        p {
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          line-clamp: 5;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  .home_news {
    margin: 40px auto;

    h2 {
      font-weight: bold;
      text-align: center;
    }

    li {
      margin-bottom: 5px;
    }
  }

  .home_process {
    // background: #ffffff;
    background: #063FC6;
    // width: 1200px;
    margin: 0 auto;
    padding: 40px 0 80px;
  }

  .title_tubiao {
    margin-bottom: 44px;
    position: relative;

    .title_text {
      font-size: 24px;
      font-weight: 400;
      color: #333333;
      margin-left: 30px;

      &::before {
        content: ' ';
        width: 5px;
        height: 27px;
        // background: #1890ff;
        background: #063FC6;
        position: absolute;
        bottom: 0;
        left: 10px;
      }
    }

    .title_text[colorspesial='true'] {
      font-size: 24px;
      font-weight: 400;
      color: #333333;
      margin-left: 30px;

      &::before {
        content: ' ';
        width: 5px;
        height: 27px;
        // background: #1890ff;
        background: #fff;
        position: absolute;
        bottom: 0;
        left: 10px;
      }
    }

    .title_more {
      float: right;
      font-size: 16px;
      line-height: 37px;
    }

    .title_modal {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 198px;
      height: 14px;
      background: linear-gradient(90deg, #1890ff, rgba(187, 2, 0, 0));
      opacity: 0.2;
    }

    .title_modal[colorspesial='true'] {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 198px;
      height: 14px;
      background: linear-gradient(90deg, #fff, rgba(187, 2, 0, 0));
      opacity: 0.2;
    }
  }

  .content-img {
    width: 100%;
    height: 230px;
    // margin-bottom: 5px;

    img {
      object-fit: cover;
      cursor: pointer;
      width: 100%;
      height: 100%;
    }

    video {
      width: 100%;
      height: 100%;
      // object-fit: cover;
    }
  }

  .news_module {
    background-color: #ffffff;
    padding-top: 40px;

    .news_module_content {
      display: flex;
      justify-content: space-between;
      width: 1200px;
      margin: 0 auto;

      .video1 {
        height: 230px;
        // width: 578px;
        width: 372px;
        margin-bottom: 20px;

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .ant-card {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.04);
  }
}

.news-card {
  margin-bottom: 20px;

  .date {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 24px;
    color: #999999;
  }

  .title {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 18px;
    color: #1890ff;
  }

  .tipText {
    width: 500px;
  }

  .detail {
    font-weight: 400;
    margin-bottom: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .button {
    width: 117px;
    height: 40px;
    border: 1px solid #1890ff;
    border-radius: 4px;
    font-size: 14px;
    color: #1890ff;
    line-height: 40px;
    display: block;
    cursor: pointer;
    text-align: center;

    &:hover {
      background: #063FC6;
      color: #ffffff;
    }
  }
}

.news-cardnew {
  // box-shadow: 0px 0px 16px 0px rgba(4, 23, 41, 0.13);
  // border: 1px solid rgba(4, 23, 41, 0.13);
  margin-bottom: 20px;

  .card-title {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 16px;

    &:hover {
      color: #063FC6;
    }
  }

  .detail {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 10px;
  }
}

.home-dynamic {
  width: 1200px;
  margin: 40px auto;
  font-size: 16px;

  .content {
    display: flex;
    justify-content: space-between;

    .d_menu {
      width: 260px;
      min-height: inherit;
      background: #ffffff;
    }

    .d_content {
      width: 920px;
      min-height: 600px;

      // background-color: #fff;
      .video1 {
        // height: 325px;
        // width: 578px;
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
        background-color: #ffffff;
        padding: 20px 0 0;

        video {
          // width: 100%;
          // height: 100%;
          width: 578px;
          height: 325px;
          margin: 0 auto 20px;
        }
      }
    }

  }
}
@primary-color: #063FC6;