.investment{

}

.card{
    background: #FBFBFB;
    padding-top:24px;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}
@primary-color: #063FC6;