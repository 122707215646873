.login {
  width: 100%;
  .title {
    text-align: center;
    font-size: 24px;
    margin: 56px 0 27px;
    color: #333;
    font-weight: 400;
  }

  .loginArea{
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    height: 100%;
    position: relative;
    padding: 40px;

    .operationArea {
      display: flex;
      justify-content: center;
      width: 100%;
  
      .formArea {
        width: 100%;
      }
  
      .qCodeArea {
        width: 182px;
        margin-right: 200px;
        text-align: center;

        img {
          width: 182px;
          height: 182px;
          margin-bottom: 8px;
        }
      }
  
      .divider {
        width: 1px;
        height: 150px;
        border-left: 1px dashed #999;
        margin: 0 40px;
      }
    }
  }

  .suffixActive {
    cursor: pointer;
    color: #1890ff;
    font-size: 14px;
  }

  .suffixDisable {
    font-size: 14px;
    color: #999999;
  }
  
  .success {
    text-align: center;

    .icon {
      font-size: 48px;
      color: #1890ff;
      margin-bottom: 24px;
    }

    .successText {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 40px;
    }

    .button {
      padding: 0 32px;
      border-radius: 4px;
      height: 42px;
      font-size: 18px
    }
  }

  .captchaInput {
    display: flex;
    justify-content: space-between;
    height: 32px;
    width: 100%;
    .captchaArea {
      flex-shrink: 0;
      margin-left: 8px;
    }
  }
}
@primary-color: #063FC6;