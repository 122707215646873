.news-detail {
  width: 1200px;
  margin: 40px auto;
  min-height: 600px;


  .detail-video {
    width: 600px;
    height: 350px;
    margin: 0 auto 20px;

    video {
      height: 100%;
      width: 100%;
    }
  }

  h1 {
    text-align: center;
    margin: 20px 0;
    font-weight: bold;
    white-space: pre-line;
  }

  .detail {
    margin-bottom: 20px;
    white-space: pre-wrap;
    font-size: 16px !important;
    // text-indent: 2em;

    img {
      // display: block;
      // margin: 0 auto;
      max-width: 100%;
    }

    // word-wrap: break-word;
    p {
      margin-bottom: 0;
    }
  }

  .detail-files {
    a {
      display: block;
      margin-bottom: 10px;
    }

    p {
      width: fit-content;
      cursor: pointer;
      color: #1890FF;
    }
  }

  .date {
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
  }

  .source {
    margin-bottom: 20px;
    text-align: right;
    margin-top: 20px;
    font-size: 16px;
  }
}

.news {
  margin: 40px auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;

  .menu {
    width: 260px;
    min-height: inherit;
    background: #ffffff;
    flex-shrink: 0;
    margin-right: 20px;

    .curItem {
      &:hover {
        .ant-menu-submenu-title {
          color: #ffffff;
        }
      }
    }
  }

  .content {
    width: 920px;
    min-height: 550px;
    .defalut_img{
      text-align: center;
      height: 550px;
      width: 100%;
      line-height: 550px;
      background-color: #ffffff;
    }
    .contentList {
      min-height: 550px;
    }

    // background: #fff;
    // padding: 20px;
    // text-align: center;
    // padding-top: 100px;
  }
}


.newsCard {
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  .leftContent {
    flex: 1;
    margin-right: 46px;
    min-width: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h3 {
      margin-bottom: 1em;

      &:hover {
        color: #063FC6;
      }
    }

    .detail {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: #666666;
    }

    .date {
      margin-bottom: 0;
      color: #666666;
    }
  }

  img {
    width: 158px;
    height: 120px;
    border-radius: 4px;
    object-fit: cover;
  }

  video {
    width: 158px;
    height: 120px;
    border-radius: 4px;
  }

}
@primary-color: #063FC6;