.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
  border-bottom: none;
}

.ant-menu-horizontal>.ant-menu-item::after,
.ant-menu-horizontal>.ant-menu-submenu::after {
  border-bottom: none;
}

.ant-menu-horizontal {
  border-bottom: none;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #063FC6;
  color: #ffffff;
}

.ant-menu-title-content {
  font-size: 16px;
}

.ant-card-body {
  padding: 16px 24px;
}

// .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover,
// .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover,
// .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active,
// .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active,
// .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open,
// .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected {
  color: #ffffff;
  background-color: #063FC6;
}


element.style {}

.layout .header .menu .ant-menu-title-content {
  font-size: 16px;
}

.ant-menu-title-content {
  font-size: 16px;
}

.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}

.ant-menu-title-content {
  transition: color 0.3s;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected {
  color: #ffffff;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected {
  color: #ffffff;
  background-color: #063FC6;
}

// .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover,
// .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active {
//   color: #1890ff;
// }

.ant-menu-submenu-selected {
  background-color: #063FC6;
  color: #ffffff;
}

// .ant-menu-light .ant-menu-submenu-title:hover {
//   color: #1890ff;
// }

.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
  height: 50px;
  line-height: 49px;
}

table td,
table th {
  border: 1px solid #c5c5c5;
  padding: 5px 12px;
}
@primary-color: #063FC6;