.parsonal {
  margin: 40px auto;
  width: 1200px;
  display: flex;
  justify-content: space-between;

  .p_menu {
    width: 260px;
    min-height: inherit;
    background: #ffffff;
  }

  .p_content {
    width: 920px;
    height: 600px;
  }
}

.myRegister {
  width: 100%;
  background-color: #fff;
  padding: 27px 32px 21px;

  .title {
    font-size: 16px;
    font-weight: 400;
    color: #333;
    margin-bottom: 20px;
  }

  .messageTitle {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }

  .messageInfo {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #8f8f8f;
    margin-bottom: 16px;
  }

  .divider {
    width: 100%;
    height: 1px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;

    .detailInfo {
      width: 500px;
      display: flex;
      flex-wrap: wrap;
      font-size: 12px;

      .detailItem {
        width: 250px;
        padding-right: 8px;
      }
    }

    .succuss {
      font-size: 20px;
      font-weight: 400;
      color: #333;

      .icon {
        color: #52C41A;
        margin-right: 16px
      }
    }

    .detailLabel {
      color: #666;
    }

    .detailValue {
      color: #333;
    }
  }

  .foot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    .buttonArea {
      display: flex;

      .button {
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 4px;
        border: 1px solid #063FC6;
        padding: 0 30px;
        font-size: 14px;
        color: #063FC6;
        font-weight: 400;
        margin-right: 30px;
        cursor: pointer;
      }
    }

    .qqInfo {
      font-size: 14px;
      font-weight: 500;
      color: #333;
    }
  }

  .userInfo {
    display: flex;
    align-items: center;
    height: 60px;

    .userLabel {
      width: 150px;
      text-align: right;
    }

    .userValue {
      width: 450px;
      margin-right: 40px;
    }

    .userAction {
      color: #063FC6;
      cursor: pointer;
    }
  }
}

.registerDetail {
  width: 100%;

  .infoArea {
    max-width: 1200px;
    margin: 0 auto;

    .cardTitle {
      height: 24px;
      line-height: 24px;
      font-size: 24px;
      font-weight: 400;
      color: #333;
      margin: 30px 0 35px;
      position: relative;
      padding-left: 32px;
      // background: linear-gradient(90deg, #BB0200, rgba(187, 2, 0, 0.2));

      &::before {
        content: '';
        height: 27px;
        width: 5px;
        background-color: #063FC6;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 8px;
      }

      &::after {
        content: '';
        width: 198px;
        height: 14px;
        // background: linear-gradient(90deg, #BB0200, rgba(187, 2, 0, 0));
        background: linear-gradient(90deg, #063FC6, rgba(187, 2, 0, 0));
        opacity: 0.2;
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: -3px;
      }
    }

    .cardArea {
      background: #fff;
      padding: 36px 30px;
    }

    .teamItem {
      font-size: 20px;
      font-weight: 400;
      color: #333;
      margin-bottom: 18px;
    }

    .fieldItem {
      display: flex;
      margin-bottom: 16px;
      color: #333;
      font-size: 14px;

      .fieldLabel {
        width: 180px;
        text-align: right;
        flex-shrink: 0;
        padding-right: 34px;
      }

      .fieldValue {
        white-space: pre-line;
        width: 100%;
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .navigationArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 54px 0 45px;
    font-weight: 500;
    color: #333;

    .bread {
      .backList {
        cursor: pointer;
      }

      .detail {
        color: #063FC6;
      }
    }

    .button {
      width: 82px;
      height: 33px;
      border: 1px solid #063FC6;
      color: #063FC6;
      border-radius: 17px;
      text-align: center;
      line-height: 33px;
      cursor: pointer;
    }
  }
}

.suffixActive {
  cursor: pointer;
  color: #063FC6;
  font-size: 14px;
}

.suffixDisable {
  font-size: 14px;
  color: #999999;
}
@primary-color: #063FC6;