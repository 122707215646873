.globalTel {
  width: 100%;
  display: flex;
  justify-content: space-between;

  :global{
    .ant-select-dropdown {
      width: 200px !important;
    }
  }

  .dropdown-style {
    .ant-select-dropdown {
      width: 200px !important;
    }
  }
}
@primary-color: #063FC6;