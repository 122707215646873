.enterPage {
  width: 1200px;
  margin: 40px auto;

  .cardTitle {
    width: 100%;
    text-align: center;
    display: block;
  }

  .formBox {
    margin-top: 40px;

    .redioItem {
      display: flex;

      .item {
        margin-right: 40px;
        flex-shrink: 0;
      }

      .extra {
        color: #999999;
      }
    }
  }

  li {
    list-style: none;
    margin-bottom: 10px;

    span {
      color: #ff6a00;
    }
  }

  .groups {
    span {
      display: block;
    }
  }

  .sectionText {
    margin-bottom: 10px;
    text-indent: 2em;
  }
}

.explain {
  margin: 40px auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;

  .menu {
    width: 260px;
    min-height: inherit;
    background: #ffffff;
    flex-shrink: 0;
    margin-right: 20px;
  }

  .content {
    width: 100%;
    background: #fff;
    padding: 20px;

    .html {
      white-space: pre-wrap;
      margin-bottom: 20px;

      img {
        // display: block;
        // margin: 0 auto;
        max-width: 100%;
      }
    }

    .detail-files {
      a {
        display: block;
        margin-bottom: 10px;
      }

      p {
        width: fit-content;
        cursor: pointer;
        color: #1890FF;
      }
    }
  }

  .sectionText {
    margin-bottom: 10px;
    text-indent: 2em;
    font-size: 16px;
  }

  .mainText {
    font-weight: bold;
    font-size: 16px;
  }

  .moreEmpty {
    text-indent: 7em;
  }

  .important {
    font-weight: bold;
  }

  table {
    width: 100%;
    font-size: 16px;
    margin-bottom: 10px;
  }

  th {
    height: 40px;
  }

  td {
    height: 40px;
    padding: 0 16px;
    min-width: 180px;
  }

  .textCenter {
    text-align: center;
  }
}
@primary-color: #063FC6;